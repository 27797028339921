import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconZzp: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '2.01 4.06 59.99 57.94', width: '59.99', height: '57.94' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<g>
				<path
					d={`M36.061,26.8193c0,2.6557-1.2385,4.6483-2.9114,6.5667c-2.6125,2.996-5.2689,4.4426-9.0748,2.7885
c1.3991,2.1209,3.6667,2.9814,5.9349,2.7835c3.2185-0.2807,5.7593-3.3948,7.3903-6.1989c0.7901-1.3583,1.3024-2.7485,1.532-4.2024
C40.2602,27.3372,41,25.3911,41,23.001c0-0.7447-0.0088-2.7025-1.2368-4.0366c0.3036-3.3378,0.525-8.5034-0.7769-10.587
C37.3837,5.8126,35.4365,4.5615,31.7231,5.4c-2.4355-1.1577-4.0937-1.8823-8.3608-0.8398c-2.9648,0.7241-6.21,1.9067-7.92,3.8798
c-1.652,1.9066-1.5394,7.2237-1.2741,10.6054c-1.1589,1.332-1.1634,3.2256-1.1634,3.9557c0,2.386,0.7374,4.3299,2.0614,5.5497
c0.3819,2.5118,1.613,4.9141,3.7105,7.2619c-0.106,0.807-0.2283,1.7498-0.353,2.7313c-3.1678,0.926-9.8019,3.1497-12.0242,5.5665
C3.5259,47.2344,2.0123,59,2.0123,59h31.3984h0.0523c-0.4154-2.9713-0.5107-7.0173-0.5107-10
c0-2.0401,0.0474-4.5734,0.2084-6.9241c-0.7892,2.9274-3.7352,5.8048-5.7674,7.2639c-1.9927-1.5159-5.4742-4.5554-6.0898-7.6113
C21.14,40.925,21.2112,40.5142,21.304,39.75h-0.0099c0.1341-1.105,0.0663-0.5752,0.754-4.8696
c-2.2767-2.2-4.1043-4.8665-4.1043-8.061c-1.6177-0.5039-2.0414-2.5454-2.0414-3.8183c0-1.2725,0.3217-1.961,0.7737-2.1743
c0.3922-0.1844,0.7725-0.0475,1.013,0.0886c0.1414,0.6527,0.2484,1.1383,0.2898,1.3098c0.2061,0.8511,1.0234,0.7729,1.0234,0.7729
v-6.7451c2.0731-0.1505,6.1937-0.7531,10.7499-3.1062l5.2501,3.5262v6.3251c0,0,0.8174,0.0782,1.0234-0.7729
c0.0415-0.1715,0.1484-0.6571,0.2898-1.3098c0.2405-0.1362,0.6209-0.2731,1.013-0.0886c0.4521,0.2133,0.7738,0.9018,0.7738,2.1743
C38.1024,24.2739,37.6786,26.3154,36.061,26.8193z`}
				/>
				<path
					d={`M55.6859,45.8906H55v2.3093c0.2695,0.0349,0.268,0.0463,0.5375,0.0463c1.1047,0,1.6282-0.5251,1.6282-1.2127
S56.7635,45.8906,55.6859,45.8906z`}
				/>
				<path
					d={`M61.435,38.826c-0.195-1.1729-1.0873-2.0652-2.2607-2.2607C55.7828,36,50.1301,36,49.0001,36
c-1.1301,0-6.7828,0-10.1743,0.5654c-1.1735,0.1955-2.0657,1.0878-2.2607,2.2607C36,42.2173,36,47.8696,36,49
s0,6.7827,0.5651,10.174c0.195,1.1729,1.0873,2.0652,2.2607,2.2607C42.2172,62,47.8699,62,49.0001,62
c1.1301,0,6.7828,0,10.1742-0.5654c1.1735-0.1955,2.0657-1.0878,2.2607-2.2607C62,55.7827,62,50.1304,62,49
C62,45.607,62,42.2173,61.435,38.826z M45.0498,53H39v-1.9041L42.6,46v-0.0552H39V44h6.0227v1.9041L41.4,51v0.0552h3.6498V53z
M52,45.9041L48.4,51v0.0552H52V53h-6.0498v-1.9041L49.6,46v-0.0552H46V44h6V45.9041z M55.8696,50.0441
c-0.3636,0-0.5458-0.0135-0.8696-0.0542V53h-2v-9h3.0583c1.9803,0,3.0717,1.2113,3.0717,2.858
C59.13,48.7786,57.8365,50.0441,55.8696,50.0441z`}
				/>
			</g>
		</g>
	</svg>
);
