import React from 'react';

import { unexisty } from '@dop/shared/helpers/functional';

import { IconStarters } from './svg/starters';
import { IconWerkgelegenheid } from './svg/werkgelegenheid';
import { IconBudget } from './svg/budget';
import { IconWorld } from './svg/world';
import { IconInnovatie } from './svg/innovatie';
import { IconKoopwoning } from './svg/koopwoning';
import { IconOndernemen } from './svg/ondernemen';
import { IconOndernemingen } from './svg/ondernemingen';
import { IconOog } from './svg/oog';
import { IconBelastingen } from './svg/belastingen';
import { IconZzp } from './svg/zzp';
import { IconGrootstedelijk } from './svg/grootstedelijk';
import { IconInvulformulier } from './svg/invulformulier';
import { IconHandel } from './svg/handel';
import { IconLandbouw } from './svg/landbouw';
import { IconReisdocument } from './svg/8037-reisdocument';
import { IconNeutraal } from './svg/8026-neutraal';
import { IconCorona } from './svg/corona';

/* eslint-disable complexity */
export const getThemeIcon = (icon) => {
	switch (icon) {
		case '6005-starters':
			return IconStarters;
		case '4094-werkgelegenheid':
			return IconWerkgelegenheid;
		case 'budget-9000':
			return IconBudget;
		case '6033-zzp':
			return IconZzp;
		case '9004-belastingen':
			return IconBelastingen;
		case 'invulformulier':
			return IconInvulformulier;
		case '6019-innovatie':
			return IconInnovatie;
		case '5000-grootstedelijk':
			return IconGrootstedelijk;
		case 'world':
			return IconWorld;
		case '6023-ondernemen':
			return IconOndernemen;
		case '6024-ondernemingen':
			return IconOndernemingen;
		case '1008-oog':
			return IconOog;
		case '9012-koopwoning':
			return IconKoopwoning;
		case '6020-landbouw':
			return IconLandbouw;
		case '6034-handel':
			return IconHandel;
		case '8037-reisdocument':
			return IconReisdocument;
		case '8026-neutraal':
			return IconNeutraal;
		case 'corona':
			return IconCorona;
		default:
			return null;
	}
};

export const ThemaIcon = (props) => {
	const { icon, ...rest } = props;
	const Icon = getThemeIcon(icon);

	if (unexisty(Icon)) {
		return null;
	}

	return <Icon {...rest} />;
};
