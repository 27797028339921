import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconLandbouw: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '1.68 9 61.32 46', width: '61.32', height: '46' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<g>
				<path
					d={`M16,31C9.3726,31,4,36.3726,4,43c0,6.6274,5.3726,12,12,12s12-5.3726,12-12C28,36.3726,22.6274,31,16,31z M16,48.25
c-2.8995,0-5.25-2.3505-5.25-5.25s2.3505-5.25,5.25-5.25s5.25,2.3505,5.25,5.25S18.8995,48.25,16,48.25z`}
				/>
				<path
					d={`M53,32.88c0.6829,0,1.348,0.0701,2,0.1786v-5.3735c0-1.5286-1.1494-2.8129-2.6687-2.9816L47,24.1111V15.5
c0-0.8284-0.6716-1.1459-1.5-1.1459S44,14.6716,44,15.5v8.2778l-4.0291-0.4477L38.1396,11.376C37.9287,9.999,36.7646,9,35.3721,9
H14.2236l-2.2975,15H9.01c-0.6631,0-1.2988,0.2632-1.7676,0.7324l-5,5c-0.9766,0.9761-0.5766,2.1591,0.4,3.1351
c0.9766,0.9766,2.1586,1.3766,3.1352,0.4L10.0452,29h11.9297L30,37.0256V42c0,1.6569,1.3431,3,3,3h7.88
C40.88,38.3063,46.3063,32.88,53,32.88z M22,24h-5.0161l1.5327-10H22V24z M35.2984,34.7404
c0.1521,0.2963-0.0811,0.4341-0.0811,0.4341L25,24.9548V14h8.4834l2.4515,16H32.5C32.5,30,35.1463,34.444,35.2984,34.7404z`}
				/>
				<path
					d={`M53,35c-5.5228,0-10,4.4771-10,10c0,5.5228,4.4772,10,10,10s10-4.4772,10-10C63,39.4771,58.5228,35,53,35z M53,49.2
c-2.3196,0-4.2-1.8805-4.2-4.2c0-2.3196,1.8804-4.2,4.2-4.2s4.2,1.8804,4.2,4.2C57.2,47.3195,55.3196,49.2,53,49.2z`}
				/>
			</g>
		</g>
	</svg>
);
