import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconStarters: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '7.05 4.21 50.87 54.79', width: '50.87', height: '54.79' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<g>
				<path
					d={`M20.2039,34.1192c4.4054,1.8267,7.5238-0.3654,7.5238-0.3654s-0.3079-5.6232-4.9608-7.0845
c-3.2921-1.0339-5.8849-0.4286-7.1199,0.014c-0.4681,0.1677-0.7291,0.6632-0.5882,1.14
C15.5158,29.37,16.8642,32.7344,20.2039,34.1192z`}
				/>
				<path
					d={`M46.0538,49c-4.5999,0-7.8086-2.3785-11.0945-3.808c-2.1486-6.2708-2.1685-12.7421-0.6865-18.1953
c9.426,1.0221,15.7916-11.9625,11.1957-22.1992c-0.3043-0.6777-1.2278-0.7886-1.6984-0.2137
c-7.9283,9.6857-13.6627,4.7379-17.0053,12.0009c-1.2411,2.697-0.5916,6.1821,1.8977,8.3192
c2.1403-4.0284,4.8118-7.0964,6.7656-8.7288c0.2961-0.2474,0.4708-0.1625,0.5469-0.0978
c-5.1477,8.2722-8.205,18.0773-6.0743,28.0905c-5.4877,0.4498-9.296,5.9316-13.947,6.7773
C11.2902,51.7929,7.0508,53.9127,7.0508,59h50.8735C57.9243,59,52.5833,49,46.0538,49z`}
				/>
			</g>
		</g>
	</svg>
);
