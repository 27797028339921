import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconWorld: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '7 5 51 54', width: '51', height: '54' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<path d="M53.2261243,24.1824518 L58,19.6573137 C53.5425817,11.206975 44.4695165,5.33289213 33.9096804,5 C31.9983934,7.21775664 29.2660539,10.9207243 30.0707048,13.2079859 C31.2767162,16.6375065 36.8243688,22.3533742 36.8243688,22.3533742 C36.8243688,22.3533742 29.3470979,23.0392783 29.1058956,28.7551459 C28.8646933,34.4710135 36.1007619,38.1291688 36.8243688,40.6441506 C37.5479757,43.1591324 38.7539871,48.875 38.7539871,48.875 C38.7539871,48.875 45.9900557,42.9304977 45.5076511,41.5586894 C45.0252466,40.1868812 43.5780328,36.7573606 44.542842,35.1569177 C45.5076511,33.5564747 50.5728992,32.4133012 50.3316969,30.5842236 C50.0904946,28.7551459 45.5076511,23.267913 45.5076511,23.267913 L47.4372694,17.78068 C47.4372694,17.78068 51.5377083,19.1524883 51.296506,20.5242965 C51.0553037,21.8961047 53.2261243,24.1824518 53.2261243,24.1824518" />
			<path d="M16.4628832,10.0625 C10.693495,14.9737186 7,22.4874396 7,30.9148541 C7,38.6798639 10.1361847,45.6688347 15.1423382,50.5625 C16.0151072,48.5337122 17.1332328,45.7270376 17.5152883,43.8488383 C18.1724938,40.6153423 11.1623016,33.6864222 11.3813701,30.9148541 C11.6004386,28.1432861 18.8296994,28.1432861 18.3915623,26.295574 C17.9534253,24.447862 15.7627403,22.60015 15.7627403,22.60015 C15.7627403,22.60015 19.2678364,22.369186 20.1441104,20.752438 C21.0203844,19.1356899 20.5822474,16.8260499 19.2678364,14.2854459 C18.6702175,13.1306258 17.530185,11.50002 16.4628832,10.0625" />
			<path d="M39.6633065,53.3857932 C37.1735773,54.087569 34.4575091,51.0465403 30.6097459,50.5786897 C28.0077526,50.2624228 26.4414866,54.6508611 25.7,57.5272064 C28.3753271,58.4769431 31.2434951,59 34.2311701,59 C39.9285758,59 45.1968427,57.118305 49.5,53.9322426 C49.339752,53.3633363 49.0934951,52.8393437 48.716867,52.450092 C47.1324939,50.812615 42.1530356,52.6840173 39.6633065,53.3857932" />
			<path d="M52.9,33.6875 C51.9616,33.6875 51.2,34.8202344 51.2,36.21875 C51.2,37.6172656 51.9616,38.75 52.9,38.75 C53.8384,38.75 54.6,37.6172656 54.6,36.21875 C54.6,34.8202344 53.8384,33.6875 52.9,33.6875" />
		</g>
	</svg>
);
