import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconPijlRechts: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '11 13.61 41.39 36.78', width: '41.39', height: '36.78' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<path
				d={`M51.0996,29.3403l-19-15c-1.2715-1.0058-3.0801-0.9682-4.3096,0.0909c-1.2295,1.0576-1.5371,2.8388-0.7334,4.248
L31.8025,27H14.5264C11.8096,27,10.999,29.3491,11,32.0015C10.999,34.6519,11.8086,37,14.5264,37h23.7802
c0,0,0.1543,0.2578-0.3216,0.3863c-0.5962,0.161-7.5495,2.0104-7.5495,2.0104l-3.3789,5.9241
c-0.8037,1.4092-0.4961,3.1904,0.7334,4.248c0.6338,0.5459,1.4219,0.8199,2.21,0.8199c0.7412,0,1.4834-0.2417,2.0996-0.729l19-15
c0.8145-0.6426,1.2891-1.6226,1.2891-2.6597C52.3887,30.9629,51.9141,29.9829,51.0996,29.3403z`}
			/>
		</g>
	</svg>
);
