import React from 'react';

import { config } from 'moduleAlias/config';
import { SvgContent } from '@dop/ui-primitives/media/Svg';

import { IconKoopwoningEN } from './koopwoningEN';
import { IconKoopwoningNL } from './koopwoningNL';

export const IconKoopwoning: SvgContent = (props) => {
	const langCode = config.languageCode;

	return langCode === 'en' ? (
		<IconKoopwoningEN {...props} />
	) : (
		<IconKoopwoningNL {...props} />
	);
};
