import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconKoopwoningNL: SvgContent = ({ cropped = false, ...props }) => {
	return (
		<svg
			{...(cropped
				? { viewBox: '4 5 56 55', width: '56', height: '55' }
				: { viewBox: '0 0 64 64', width: '64', height: '64' })}
			{...props}
		>
			<g>
				<rect fill="none" width="64" height="64" />
			</g>
			<g>
				<g>
					<path
						d={`M35.7768,33.5083c-0.9233,0-1.9102,0.6392-1.9102,2.4326c0,1.6372,0.6782,2.5391,1.9102,2.5391
c0.918,0,1.8984-0.6357,1.8984-2.4209C37.6752,34.3901,37.019,33.5083,35.7768,33.5083z`}
					/>
					<path
						d={`M44.6467,33.5083c-0.9233,0-1.9102,0.6392-1.9102,2.4326c0,1.6372,0.6782,2.5391,1.9102,2.5391
c0.918,0,1.8984-0.6357,1.8984-2.4209C46.5451,34.3901,45.8889,33.5083,44.6467,33.5083z`}
					/>
					<path
						d={`M58.069,5H5.931C4.8646,5,4,5.9661,4,7.1579v36.6842C4,45.0339,4.8646,46,5.931,46H28v14h8V47.5386l-5.775-1.1494
c-0.3461-0.111-0.2955-0.3359-0.2727-0.3972L58.069,46C59.1354,46,60,45.0339,60,43.8421V7.1579C60,5.9661,59.1354,5,58.069,5z
M15.3047,33.5322h-2.1602v6.6108h-1.936v-6.6108H9.0483v-1.6753h6.2563V33.5322z M20.8556,40.1431h-4.6191v-8.2861h4.5835v1.6753
h-2.6587v1.5171h2.4453v1.6753h-2.4453v1.7432h2.6943V40.1431z M29.4577,40.1431l-2.2549-3.3945h-0.2925v3.3945H24.986v-8.2861
h1.9243v3.2285h0.2632l2.3506-3.2285h2.2637l-2.9209,3.9126l2.978,4.3735H29.4577z M35.6938,40.25
c-2.3579,0-3.8228-1.6011-3.8228-4.1787c0-2.9854,2.0034-4.3213,3.9888-4.3213c2.3506,0,3.811,1.6011,3.811,4.1787
C39.6708,38.9141,37.6733,40.25,35.6938,40.25z M40,28h-6v-6h-4v6h-6v-8h-1.6c-0.2209,0-0.4-0.1791-0.4-0.4v-1.1926
c0-0.2236,0.0938-0.4371,0.2584-0.5886l9.4707-9.0351c0.1531-0.1408,0.3886-0.1408,0.5417,0l9.4708,9.0351
C41.9062,17.9703,42,18.1838,42,18.4073V19.6c0,0.2209-0.1791,0.4-0.4,0.4H40V28z M44.5637,40.25
c-2.3579,0-3.8228-1.6011-3.8228-4.1787c0-2.9854,2.0034-4.3213,3.9888-4.3213c2.3506,0,3.811,1.6011,3.811,4.1787
C48.5407,38.9141,46.5432,40.25,44.5637,40.25z M52.3087,37.4136c-0.23,0-0.4199-0.0063-0.6045-0.021v2.7505h-1.9243v-8.2861
h2.6948c1.8237,0,2.957,1.0098,2.957,2.6357C55.4317,36.2671,54.2056,37.4136,52.3087,37.4136z`}
					/>
					<path
						d={`M52.2613,33.4961h-0.5571v2.2593c0.1338,0.0127,0.2686,0.0186,0.4268,0.0186c0.4917,0,1.3164-0.1528,1.3164-1.1748
C53.4473,33.8467,53.0704,33.4961,52.2613,33.4961z`}
					/>
				</g>
			</g>
		</svg>
	);
};
